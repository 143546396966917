import { Box, createStyles, makeStyles } from "@material-ui/core";
import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";
import FormHelperText, {
  FormHelperTextProps
} from "@material-ui/core/FormHelperText";
import { FormikErrors, FormikTouched } from "formik";
import React from "react";
import { MyTooltip } from "../../MyTooltip";

interface MyFormHelperTextProps extends FormHelperTextProps {
  errorField?:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined
    | CustomError;
  touchField?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  isRadio?: boolean;
  label?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginLeft: 0,
      marginTop: 0,
      minHeight: 12,
      lineHeight: "1.2em"
    }
  })
);

export class CustomError {
  message: string;
  tooltip: string;
  constructor(message: string, tooltip: string) {
    this.message = message;
    this.tooltip = tooltip;
  }
}

const ErrorMessage: React.FC<{
  errorField:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | CustomError;
}> = ({ errorField }) => {
  if (
    typeof errorField === "object" &&
    "message" in errorField &&
    typeof errorField.message === "string"
  ) {
    if ("tooltip" in errorField && typeof errorField.tooltip === "string") {
      return (
        <MyTooltip
          title={errorField.tooltip}
          iconComponent={
            <Box display="flex" flexDirection="row">
              <Box mr={0.5} alignSelf="center">
                <InfoTwoToneIcon fontSize="small" />
              </Box>
              <Box alignSelf="center">{errorField.message}</Box>
            </Box>
          }
          style={{ cursor: "help" }}
        />
      );
    } else {
      return <>{errorField.message}</>;
    }
  }

  if (typeof errorField === "object") {
    // for good measure
    return null;
  }
  return <>{errorField}</>;
};

export const MyFormHelperText: React.FC<MyFormHelperTextProps> = ({
  children,
  errorField,
  touchField,
  label,
  ...formHelperTextProps
}) => {
  const classes = useStyles();
  if (label === "Height") {
    const displayError = touchField && errorField;
    const displayErrorBool = !!(touchField && errorField);
    console.log({ errorField, touchField, label, formHelperTextProps });
  }
  const displayErrorMessage = !!touchField && !!errorField;
  return (
    <FormHelperText
      error={displayErrorMessage}
      className={classes.root}
      style={{
        ...(formHelperTextProps.isRadio && {
          position: "absolute",
          bottom: "-2px"
        })
      }}
      {...formHelperTextProps}
    >
      {displayErrorMessage ? (
        <ErrorMessage errorField={errorField} />
      ) : (
        children || null
      )}
    </FormHelperText>
  );
};
