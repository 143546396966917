import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import { blue, green, orange, red } from "@material-ui/core/colors";
import CloseRounded from "@material-ui/icons/CloseRounded";
import clsx from "clsx";
import { ConnectedRouter } from "connected-react-router";
import { SnackbarProvider } from "notistack";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DynamicElement } from "../components/common/DynamicElement";
import { MyTopBar } from "../components/common/MyTopBar";
import { Notifier } from "../components/common/Notifier";
import { ScrollToTop } from "../components/common/ScrollToTop";
import { UpdateHeader } from "../components/common/UpdateHeader";
import { AppTheme } from "../components/theme/AppTheme";
import { RootState } from "../reducers";
import { AppCloseSnackbar, AppSetLanguage } from "../reducers/app/action";
import { getAppState } from "../reducers/app/selector";
import { AppSettingActions } from "../reducers/appSettings/reducer";
import { history } from "../reducers/store";
import { RootRoutes } from "../routes";
import { RoutesManager } from "../routes/RouterManager";
import { DRAWER_WIDTH } from "../utils/constant";
import TagManager from "react-gtm-module";
import { getCurrentUser } from "../reducers/authentication/selector";
import { InitMetaEntityCustomFields } from "../components/common/InitMetaEntityCustomFields";
import {
  getAllEnabledLocalisations,
  getDefaultLocalisation
} from "../reducers/appSettings/selector";
import { useUserTimeZone } from "../utils/hooks/useUserTimeZone";
import { InitSentry } from "../components/common/InitSentry";
import i18n, { I18N_CACHE_PREFIX } from "../utils/i18n";
import { LoadingPage } from "./LoadingPage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      width: "auto",
      marginRight: theme.spacing(1)
    },
    main: {
      position: "relative",
      paddingTop: 64,
      height: "100%"
    },
    mainWithMargin: {
      [theme.breakpoints.up("md")]: {
        marginLeft: DRAWER_WIDTH
      }
    },
    toolbarSpacer: theme.mixins.toolbar,
    notificationRoot: {
      marginBottom: "0px"
    },
    notificationDefault: {
      background: "white !important",
      display: "flex",
      alignItems: "center",
      flexWrap: "nowrap",
      paddingTop: 0,
      paddingBottom: 0
    },
    notificationSuccess: {
      border: `1px solid ${green[400]}`,
      color: `#66bb6a !important`
    },
    notificationError: {
      border: `1px solid ${red[400]}`,
      color: `#ef5350 !important`
    },
    notificationWarning: {
      border: `1px solid ${orange[400]}`,
      color: `#AC6600 !important`
    },
    notificationInfo: {
      border: `1px solid ${blue[400]}`,
      color: `#42a5f5 !important`
    },
    closeIcon: {}
  })
);

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { readStatus, setting } = useSelector((state: RootState) => ({
    readStatus: state.appSettings.readStatus,
    setting: state.appSettings.setting
  }));

  const user = useSelector(getCurrentUser);
  const availableLanguages = useSelector(getAllEnabledLocalisations);
  const defaultLanguageEnv = useSelector(getDefaultLocalisation);

  const { isOnBackOffice } = useSelector(getAppState);
  const { theme } = useSelector((state: RootState) => state.app);
  useUserTimeZone();

  const initializeLanguage = useCallback(() => {
    const localStorageLanguage = localStorage.getItem("i18nextLng");
    const matchLanguage =
      navigator.languages.find((lang) =>
        availableLanguages.find(
          (envLang) => envLang.id.toLowerCase() === lang.toLowerCase()
        )
      ) ||
      navigator.languages.find((lang) =>
        availableLanguages.find((envLang) =>
          envLang.id.startsWith(lang.split("-")[0])
        )
      );

    if (defaultLanguageEnv?.id) {
      localStorage.setItem("defaultLanguage", defaultLanguageEnv.id);
    }

    if (localStorageLanguage) {
      dispatch(new AppSetLanguage(localStorageLanguage));
      return;
    } else if (user.defaultLanguage) {
      dispatch(new AppSetLanguage(user.defaultLanguage.toString()));
      return;
    } else if (matchLanguage) {
      dispatch(new AppSetLanguage(matchLanguage));
      return;
    } else if (defaultLanguageEnv?.id) {
      dispatch(new AppSetLanguage(defaultLanguageEnv.id));
      return;
    }
  }, [availableLanguages, defaultLanguageEnv, dispatch, user.defaultLanguage]);

  useEffect(() => {
    initializeLanguage();
  }, [initializeLanguage, isOnBackOffice]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!setting.gtm || !setting.gtm.enabled || !setting.gtm.id) return;
    TagManager.initialize({ gtmId: setting.gtm.id });
  }, [setting.gtm]);

  // Check if the translation timestamp has changed
  useEffect(() => {
    const localTimestamp = localStorage.getItem("translationTimestamp");
    if (!localTimestamp) {
      localStorage.setItem(
        "translationTimestamp",
        new Date().getTime().toString()
      );
      return;
    }
    if (setting?.translationTimestamp) {
      if (setting.translationTimestamp > Number(localTimestamp)) {
        Object.keys(localStorage).forEach((key) => {
          if (key.startsWith(I18N_CACHE_PREFIX)) {
            localStorage.removeItem(key);
          }
        });
        i18n.reloadResources(i18n.language);
        localStorage.setItem(
          "translationTimestamp",
          setting.translationTimestamp.toString()
        );
      }
    }
  }, [setting]);

  return (
    /*
      Avoid packitoo logo, color, font & style before rendering the brand style from AppSettings
      Wait for AppSetting data before then render the proper design
    */
    <DynamicElement
      actions={{
        action: AppSettingActions.async.read(),
        status: readStatus
      }}
      loaderComponent={<LoadingPage />}
      data={setting}
    >
      {(setting) => {
        return (
          <>
            <InitSentry config={setting.sentry} />
            <InitMetaEntityCustomFields
              isCustomFields={setting?.features?.isCustomFields as boolean}
              user={user}
            />
            <UpdateHeader
              favIcon={
                (setting.favIcon?.url as string) ||
                "https://hipe-dev.s3.eu-west-3.amazonaws.com/app_setting/favicon.png"
              }
              title={(setting.style.appTitle as string) || "Packitoo - HIPE"}
            />
            <AppTheme setting={setting} theme={theme}>
              <SnackbarProvider
                maxSnack={3}
                autoHideDuration={1500}
                preventDuplicate={true}
                transitionDuration={{ enter: 225, exit: 195 }}
                classes={{
                  root: classes.notificationRoot,
                  variantSuccess: clsx(
                    classes.notificationSuccess,
                    classes.notificationDefault
                  ),
                  variantError: clsx(
                    classes.notificationError,
                    classes.notificationDefault
                  ),
                  variantWarning: clsx(
                    classes.notificationWarning,
                    classes.notificationDefault
                  ),
                  variantInfo: clsx(
                    classes.notificationInfo,
                    classes.notificationDefault
                  )
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center"
                }}
                action={(key) => (
                  <IconButton
                    key="close_notification"
                    color="inherit"
                    onClick={() =>
                      dispatch(new AppCloseSnackbar(key as string))
                    }
                  >
                    <CloseRounded className={classes.closeIcon} />
                  </IconButton>
                )}
              >
                <ConnectedRouter history={history}>
                  <>
                    <ScrollToTop />
                    <MyTopBar />
                    <Notifier />
                    <main
                      className={clsx(classes.main, {
                        [classes.mainWithMargin]: isOnBackOffice
                      })}
                    >
                      <RoutesManager routes={RootRoutes} />
                    </main>
                  </>
                </ConnectedRouter>
              </SnackbarProvider>
            </AppTheme>
          </>
        );
      }}
    </DynamicElement>
  );
};
