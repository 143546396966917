import { AxiosError } from "axios";
import { AppAddSnackbar } from "../../reducers/app/action";
import { apiSlice } from "../../utils/api/api";
import i18n from "../../utils/i18n";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { createSocketFactory } from "../../websocket";
import {
  Notification,
  NotificationAllDto,
  WebSocketMessages
} from "./notifications.type";
import { CursorBasedMetaDto, CursorPagination } from "../common/types";
import { dynamicQueryCursorUrlClean } from "../../utils/function/dynamicUrlCursor";
import { store } from "../../reducers/store";

const getSocket = createSocketFactory("notifications");

/* getSocket().then((socket) => {
  socket.on("connect", () => {
    console.log("Connected to the notifications socket");
    socket.emit("message", "Hello from the client! (notifications service)");
  });

  socket.on("disconnect", () => {
    console.log("Disconnected from the notifications socket");
  });

  socket.on("notification", (data) => {
    console.log("Notifications", data);
  });

  socket.on("hello", (data) => {
    console.log("Hello", data);
  });
}); */

const extendedApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: ["Notifications", "MyNotifications", "MyArchivedNotifications"]
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getOneNotification: build.query<Notification, string>({
        query: (id) => `notifications/${id}`,
        providesTags: (result, error, id) => [{ type: "Notifications", id }]
      }),
      getNotifications: build.query<Notification[], void>({
        query: () =>
          "notifications/?filter=type||$eq||email&sort=createdAt,DESC&limit=100",
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Notifications" as const,
                  id
                })),
                { type: "Notifications", id: "LIST" }
              ]
            : [{ type: "Notifications", id: "LIST" }]
      }),
      markAsAllRead: build.mutation<void, NotificationAllDto>({
        query: () => ({
          url: "notifications/mark-all-as-read",
          method: "POST"
        }),
        invalidatesTags: (_, __, { userId }) => [
          { type: "Notifications", id: userId }
        ],
        async onQueryStarted(
          { userId, category },
          { dispatch, queryFulfilled }
        ) {
          try {
            await queryFulfilled;
            //Update cache of all events by comments
            dispatch(
              extendedApiSlice.util.updateQueryData(
                `getUserNotifications`,
                {
                  userId,
                  ...(category && {
                    category
                  })
                },
                (draft: any) => {
                  // Update all the notifications to be read
                  draft.data = draft.data.map((notification: Notification) => {
                    notification.isRead = true;
                    return notification;
                  });
                  draft.meta.unreadCount = 0;
                }
              )
            );
            dispatch(
              new AppAddSnackbar(
                i18n.t("saga:update-success").toString(),
                "success"
              )
            );
          } catch (error) {
            dispatch(
              sendErrorNotification(
                error as AxiosError,
                i18n.t("saga:update-failed").toString()
              )
            );
          }
        }
      }),
      archiveRead: build.mutation<void, NotificationAllDto>({
        query: () => ({
          url: "notifications/archive-read",
          method: "POST"
        }),
        invalidatesTags: (_, __, { userId }) => [
          { type: "Notifications", id: userId }
        ],
        async onQueryStarted(
          { userId, category },
          { dispatch, queryFulfilled }
        ) {
          try {
            await queryFulfilled;
            //Update cache of all events by comments
            dispatch(
              extendedApiSlice.util.updateQueryData(
                `getUserNotifications`,
                {
                  userId,
                  ...(category && {
                    category
                  })
                },
                (draft: any) => {
                  // Filter out all the notifications that are read
                  draft.data = draft.data.filter(
                    (notification: Notification) => !notification.isRead
                  );
                }
              )
            );
            dispatch(
              new AppAddSnackbar(
                i18n.t("saga:update-success").toString(),
                "success"
              )
            );
          } catch (error) {
            dispatch(
              sendErrorNotification(
                error as AxiosError,
                i18n.t("saga:update-failed").toString()
              )
            );
          }
        }
      }),
      updateNotification: build.mutation<Notification, Partial<Notification>>({
        query: ({ id, category, ...patch }) => ({
          url: `notifications/${id}`,
          method: "PATCH",
          body: { ...patch }
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            // Pessimistic update
            //const { data: notification } = await queryFulfilled;

            // Optimistic update
            const notification = { ...arg };
            const currentUser = store.getState().authentication.user;
            dispatch(
              extendedApiSlice.util.updateQueryData(
                `getUserNotifications`,
                {
                  userId: currentUser.id,
                  ...(arg.category && {
                    category: arg.category
                  })
                },
                (draft: any) => {
                  const index = draft.data.findIndex(
                    (n: Notification) => n.id === notification.id
                  );

                  // Handle case when you archive a notification
                  if (notification.isArchived) {
                    draft.data.splice(index, 1);
                    if (!notification.isRead) {
                      if (draft.meta.unreadCount > 0) {
                        draft.meta.unreadCount -= 1;
                      }
                    }
                  }
                  // Handle case when you mark a notification as read
                  if (index !== -1 && !notification.isArchived) {
                    draft.data[index].isRead = notification.isRead;

                    if (notification.isRead) {
                      if (draft.meta.unreadCount > 0) {
                        draft.meta.unreadCount -= 1;
                      }
                    } else {
                      draft.meta.unreadCount += 1;
                    }
                  }
                }
              )
            );
            /*    dispatch(
              new AppAddSnackbar(
                i18n.t("saga:update-success").toString(),
                "success"
              )
            ); */
          } catch (error) {
            dispatch(
              sendErrorNotification(
                error as AxiosError,
                i18n.t("saga:update-failed").toString()
              )
            );
          }
        }
      }),
      archiveAll: build.mutation<void, NotificationAllDto>({
        query: () => ({
          url: "notifications/archive-all",
          method: "POST"
        }),
        invalidatesTags: (_, __, { userId }) => [
          { type: "Notifications", id: userId }
        ],
        async onQueryStarted(
          { userId, category },
          { dispatch, queryFulfilled }
        ) {
          try {
            await queryFulfilled;
            //Archive all notifications

            dispatch(
              extendedApiSlice.util.updateQueryData(
                `getUserNotifications`,
                {
                  userId,
                  ...(category && {
                    category
                  })
                },
                (draft: any) => {
                  draft.data = [];
                  draft.meta = { userId, unreadCount: 0 };
                }
              )
            );

            dispatch(
              new AppAddSnackbar(
                i18n.t("saga:update-success").toString(),
                "success"
              )
            );
          } catch (error) {
            dispatch(
              sendErrorNotification(
                error as AxiosError,
                i18n.t("saga:update-failed").toString()
              )
            );
          }
        }
      }),
      getUserNotifications: build.query<
        CursorPagination<Notification>,
        Partial<CursorBasedMetaDto>
      >({
        keepUnusedDataFor: 0,
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          return queryArgs.category
            ? `${endpointName}("${queryArgs.userId}_${queryArgs.category}")`
            : `${endpointName}("${queryArgs.userId}")`;
        },
        providesTags: (_, __, { userId, category }) => [
          category
            ? { type: "MyNotifications", id: `${userId}_${category}` }
            : { type: "MyNotifications", id: userId }
        ],

        query: ({ limit = 10, cursor, category }) =>
          dynamicQueryCursorUrlClean(`notifications/user`, {
            limit,
            cursor,
            category
          }),
        merge: (currentCache, newItems, args) => {
          if (!args?.arg.cursor) {
            return currentCache;
          }
          currentCache.data.push(...newItems.data);
          currentCache.meta = {
            ...newItems.meta
          };
        },
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
        transformResponse(
          baseQueryReturnValue: CursorPagination<Notification>
        ): CursorPagination<Notification> {
          return {
            data: baseQueryReturnValue?.data,
            meta: {
              ...baseQueryReturnValue?.meta
            }
          };
        },
        async onCacheEntryAdded(
          { category },
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
        ) {
          const socket = await getSocket();

          // Add the items to the previous one fetched by the HTTP query at first
          const listener = (notification: Notification) => {
            if (!notification) return;
            updateCachedData((draft) => {
              (draft.meta.unreadCount as number) += 1;
              if (category && notification.category !== category) {
                return;
              }
              draft.data.unshift(notification);
            });
          };

          try {
            await cacheDataLoaded;
            socket.on(WebSocketMessages.Notification, listener);
          } catch (err) {
            console.error(err);
          }
          await cacheEntryRemoved;
          socket.removeListener(WebSocketMessages.Notification, listener);
        }
      }),
      getUserNotificationsArchived: build.query<
        CursorPagination<Notification>,
        Partial<CursorBasedMetaDto>
      >({
        keepUnusedDataFor: 0,
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          return queryArgs.category
            ? `${endpointName}("${queryArgs.userId}_${queryArgs.category}")`
            : `${endpointName}("${queryArgs.userId}")`;
        },
        providesTags: (_, __, { userId, category }) => [
          category
            ? { type: "MyArchivedNotifications", id: `${userId}_${category}` }
            : { type: "MyArchivedNotifications", id: userId }
        ],

        query: ({ limit = 10, cursor, category }) =>
          dynamicQueryCursorUrlClean(`notifications/user-archived`, {
            limit,
            cursor,
            category
          }),
        merge: (currentCache, newItems, args) => {
          if (!args?.arg.cursor) {
            return currentCache;
          }
          currentCache.data.push(...newItems.data);
          currentCache.meta = {
            ...newItems.meta
          };
        },
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
        transformResponse(
          baseQueryReturnValue: CursorPagination<Notification>
        ): CursorPagination<Notification> {
          return {
            data: baseQueryReturnValue?.data,
            meta: {
              ...baseQueryReturnValue?.meta
            }
          };
        }
      })
    }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    overrideExisting: true //module.hot?.status() === "apply"
  });

export const {
  useGetNotificationsQuery,
  useGetOneNotificationQuery,
  useGetUserNotificationsQuery,
  useMarkAsAllReadMutation,
  useArchiveReadMutation,
  useArchiveAllMutation,
  useUpdateNotificationMutation,
  useGetUserNotificationsArchivedQuery
} = extendedApiSlice;
